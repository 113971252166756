/*
VAZNO:
----------------------------------------------------------------------------
Obid za probivanje vo vebsajtot, koristenje na sodrzini od ovoj vebsajt
ili koristenje na kodot za ovoj vebsajt e zabraneto i vo slucaj na prekrsok
na ova kje postapime soglasno zakonot na Republika S. Makedonija
----------------------------------------------------------------------------
*/
import {useState, useEffect} from 'react';
import './App.css';
import './output.css';
import { initializeApp } from "firebase/app";
import app from "./fireBaseConfig";
import { getFirestore, query, doc, getDoc, orderBy, collection, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);

let postsObj = [];
let vraboteniObj = [];
let links = [];

function Header({changePage}){
  const[menuDisplay, setMenuDisplay] = useState("hidden ");
  function exitMenu(){
    setMenuDisplay("flex mine-dissapear ");
    setTimeout(() => {
      setMenuDisplay("hidden ");
    }, 250)
  }
  function openMenu(){
    setMenuDisplay("flex ");
  }

  return <div className="z-20 fixed top-0 left-0 flex justify-between items-center w-full bg-white border-b-4 border-lime-500 shadow-lg">
    <img onClick={() => changePage('main')} className="mine-logo box-content w-36 px-24 cursor-pointer" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/kole-nehtenin.png?alt=media&token=49ef6c9e-7737-41c7-8ca5-df2083f4d989"/>
    <div className="mine-header-r-panel w-full">
      <div className="flex justify-end bg-lime-500 rounded-bl-xl text-white ">
        <div onClick={() => changePage('galerija')} className="mine-to-killer mine-text-3 py-4 px-4 cursor-pointer hover:bg-lime-600">Галерија</div>
        <div onClick={() => changePage('zanas')} className="mine-to-killer mine-text-3 py-4 px-4 cursor-pointer hover:bg-lime-600">Контакт</div>
        <div onClick={() => changePage('links')} className="mine-to-killer mine-text-3 py-4 px-4 cursor-pointer bg-amber-400 hover:bg-amber-500">Линкови <span className="font-normal">&#10140;</span></div>
      </div>  
      <div className="flex items-center gap-4 mine-text-2 font-bold text-lime-500">
        <div onClick={() => changePage('zanas')} className="mine-to-killer py-4 cursor-pointer hover:text-lime-400">За Нас</div>
        <div className="mine-w-1 h-8 rounded-full bg-lime-500"></div>
        <div onClick={() => changePage('struki')} className="mine-to-killer py-4 cursor-pointer hover:text-lime-400">Струки</div>
        <div className="mine-w-1 h-8 rounded-full bg-lime-500"></div>
        <div onClick={() => changePage('vraboteni')} className="mine-to-killer py-4 cursor-pointer hover:text-lime-400">Вработени</div>
      </div>
    </div>
    <div onClick={openMenu} className="mine-menu-icon box-content hidden flex-col w-10 px-4 gap-2 cursor-pointer">
      <div className="bg-lime-500 h-1 rounded-full"></div>
      <div className="bg-lime-500 h-1 rounded-full"></div>
      <div className="bg-lime-500 h-1 rounded-full"></div>
    </div>
    <div className={menuDisplay + "mine-mobile-menu fixed flex-col items-end pr-4 justify-center gap-4 right-0 top-0 h-screen w-1/2 bg-lime-500 mine-shadow-Menu"}>
      <div onClick={() => {changePage('zanas'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">За Нас</div>
      <div onClick={() => {changePage('struki'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">Струки</div>
      <div onClick={() => {changePage('vraboteni'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">Вработени</div>
      <div onClick={() => {changePage('galerija'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">Галерија</div>
      <div onClick={() => {changePage('zanas'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">Контакт</div>
      <div onClick={() => {changePage('links'); exitMenu()}} className="mine-to-killer mine-text-2 text-white/90 hover:text-white font-bold cursor-pointer">Линкови</div>
      <div onClick={exitMenu} className="mine-text-1 text-white/90 hover:text-white absolute top-4 left-4 mine-mirror cursor-pointer">&#10140;</div>
    </div>
  </div>
}

function Footer({changePage}){
  let date = new Date();
  let yearNow = date.getFullYear();
  return <div className="flex flex-col w-full mt-24">
    <iframe className="w-full shadow-lg" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.7303073730072!2d22.18006507645395!3d41.74790967355475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1355c32cd4993733%3A0x98c84324101d495d!2sHigh%20School%20%22Kole%20Nehtenin%22!5e0!3m2!1sen!2smk!4v1727611216517!5m2!1sen!2smk" height="600" allowfullscreen="" loading="fast" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div className="bg-lime-500 pt-12 pb-4 flex flex-col gap-12 items-center justify-end">
      <div className="flex flex-wrap justify-center gap-12 mx-4">
        <div className="flex flex-col gap-4">
          <img className="box-content w-64 bg-white p-4 rounded-xl" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/kole-nehtenin.png?alt=media&token=49ef6c9e-7737-41c7-8ca5-df2083f4d989"/>
          <div className="flex w-full justify-between">
            <div className="flex flex-col gap-2">
              <div onClick={() => changePage('zanas')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">За Нас</div>
              <div onClick={() => changePage('struki')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">Струки</div>
              <div onClick={() => changePage('vraboteni')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">Вработени</div>
            </div>
            <div className="flex flex-col gap-2">
              <div onClick={() => changePage('galerija')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">Галерија</div>
              <div onClick={() => changePage('zanas')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">Контакт</div>
              <div onClick={() => changePage('links')} className="mine-to-killer mine-text-2 text-white font-bold cursor-pointer hover:text-white/80">Линкови</div>
            </div>
          </div>
        </div>
        <div className="w-fit flex flex-col gap-4">
          <div className="mine-text-2 font-bold text-lime-500 bg-white px-4 py-2 w-full">Контакт</div>
          <div className="w-full">
            <div className="mine-text-2 text-white font-bold w-full border-b-4 border-white">Тел.бр</div>
            <div className="mine-text-2 text-white">+389 32 391 384</div>
            <div className="mine-text-2 text-white">+389 32 ​607 384</div>
          </div>
          <div className="w-full">
            <div className="mine-text-2 text-white font-bold w-full border-b-4 border-white">E-Mail</div>
            <div className="mine-text-2 text-white">kontakt.kolenehtenin@gmail.com</div>
          </div>
          <div className="w-full">
            <div className="mine-text-2 text-white font-bold w-full border-b-4 border-white">Адреса</div>
            <div className="mine-text-2 text-white">Булевар Гоце Делчев бр. 101</div>
            <div className="mine-text-2 text-white">Штип, 2000</div>
          </div>
        </div>
      </div>
      <div className="mine-text-3 text-white font-bold text-center mx-4">© {yearNow}. All rights reserved. Website made by Teo Kocev</div>
    </div>
  </div>
}

function Main({changePage}){
  const[mainPostsIt, setMainPostsIt] = useState([0, 1, 2]);
  const date1 = new Date(postsObj[0]["date-posted"].seconds * 1000);
  const date2 = new Date(postsObj[1]["date-posted"].seconds * 1000);
  const date3 = new Date(postsObj[2]["date-posted"].seconds * 1000);
  const date1Arr = [date1.getDate(), date1.getMonth() + 1, date1.getFullYear()];
  const date2Arr = [date2.getDate(), date2.getMonth() + 1, date2.getFullYear()];
  const date3Arr = [date3.getDate(), date3.getMonth() + 1, date3.getFullYear()];
  const[mainPosts, setMainPosts] = useState([
    {title:postsObj[0].title, text:postsObj[0].text, date:date1Arr, images:postsObj[0].images},
    {title:postsObj[1].title, text:postsObj[1].text, date:date2Arr, images:postsObj[1].images},
    {title:postsObj[2].title, text:postsObj[2].text, date:date3Arr, images:postsObj[2].images}
  ]);
  const[unclickable, setUnclickable] = useState([" bg-lime-400", " bg-lime-500"]);

  useEffect(() => {
    if(window.innerWidth <= 408)
      document.querySelector(".mine-br-ucenici").innerHTML = "680 ученици!";
    window.addEventListener("resize", () => {
      if(window.innerWidth <= 408){
        try{
          document.querySelector(".mine-br-ucenici").innerHTML = "680 ученици!";
        }
        catch(err){
          console.log(err);
        }
      }
      else{
        try{
          document.querySelector(".mine-br-ucenici").innerHTML = "680 запишани ученици!";
        }
        catch(err){
          console.log(err);
        }
      }
    });
    document.querySelector("#threeL1").classList.add("mine-loader");
    let i = 1;
    let to = setTimeout(() => {
      if(document.querySelector("#threeB" + i) == null)
        return;
      document.querySelector("#threeB2").click();
    },10000);
    document.querySelectorAll(".mine-cp-posts").forEach(el => {
      el.addEventListener("click", () => {
        clearTimeout(to);
        changePage("posts");
      })
    });
    // document.querySelectorAll(".mine-cp-post").forEach(el => {
    //   el.addEventListener("click", () => {
    //     clearTimeout(to);
    //     changePage("post");
    //   })
    // });
    document.querySelectorAll(".mine-cp-struki").forEach(el => {
      el.addEventListener("click", () => {
        clearTimeout(to);
        changePage("struki");
      })
    });
    document.querySelectorAll(".mine-to-killer").forEach(el => {
      el.addEventListener("click", () => {
        clearTimeout(to);
      });
    });

    document.querySelectorAll(".mine-loaders").forEach((el) => {
      el.addEventListener("click", () => {
        if(parseInt(el.id[el.id.length - 1]) == i){
          return;
        }
        clearTimeout(to);
        document.querySelectorAll(".mine-wl-" + i).forEach(element => {
          element.style = "display:none;";
        })
        document.querySelector("#threeL1").classList.remove('mine-loader');
        document.querySelector("#threeL2").classList.remove('mine-loader');
        document.querySelector("#threeL3").classList.remove('mine-loader');
        i = parseInt(el.id[el.id.length - 1]);
        document.querySelector("#threeL" + i).classList.add("mine-loader");
        document.querySelectorAll(".mine-wl-" + i).forEach(element => {
          element.style = "display:block;";
        })
        to = setTimeout(() => {
          if(document.querySelector("#threeB" + i) == null)
            return;
          document.querySelector("#threeB" + ((i <= 2) ? i + 1 : 1)).click();
        },10000);
      })
    });
  }, [])

  function changeMainPostsContent(varArr){
    const date1 = new Date(postsObj[varArr[0]]["date-posted"].seconds * 1000);
    const date2 = new Date(postsObj[varArr[1]]["date-posted"].seconds * 1000);
    const date3 = new Date(postsObj[varArr[2]]["date-posted"].seconds * 1000);
    const date1Arr = [date1.getDate(), date1.getMonth() + 1, date1.getFullYear()];
    const date2Arr = [date2.getDate(), date2.getMonth() + 1, date2.getFullYear()];
    const date3Arr = [date3.getDate(), date3.getMonth() + 1, date3.getFullYear()];
    setMainPosts([
      {title:postsObj[varArr[0]].title, text:postsObj[varArr[0]].text, date:date1Arr, images:postsObj[varArr[0]].images},
      {title:postsObj[varArr[1]].title, text:postsObj[varArr[1]].text, date:date2Arr, images:postsObj[varArr[1]].images},
      {title:postsObj[varArr[2]].title, text:postsObj[varArr[2]].text, date:date3Arr, images:postsObj[varArr[2]].images}
    ]);
  }

  function incMainPostsIt(){
    let varArr = [];
    if(mainPostsIt[2] == postsObj.length - 1)
      return;
    else if(mainPostsIt[2] == postsObj.length - 2)
      mainPostsIt.forEach(el => {
        varArr.push(el + 1);
      });
    else if(mainPostsIt[2] == postsObj.length - 3)
      mainPostsIt.forEach(el => {
        varArr.push(el + 2);
      });
    else
      mainPostsIt.forEach(el => {
        varArr.push(el + 3);
      });
    if(varArr[2] == postsObj.length - 1)
      setUnclickable([" bg-lime-500", " bg-lime-400"]);
    else
      setUnclickable([" bg-lime-500", " bg-lime-500"]);
    setMainPostsIt(varArr);
    changeMainPostsContent(varArr);
  }
  function decMainPostsIt(){
    let varArr = [];
    if(mainPostsIt[0] == 0)
      return;
    else if(mainPostsIt[0] == 1)
      mainPostsIt.forEach(el => {
        varArr.push(el - 1);
      })
    else if(mainPostsIt[0] == 2)
      mainPostsIt.forEach(el => {
        varArr.push(el - 2);
      })
    else
      mainPostsIt.forEach(el => {
        varArr.push(el - 3);
      })
      if(varArr[0] == 0)
        setUnclickable([" bg-lime-400", " bg-lime-500"]);
      else
        setUnclickable([" bg-lime-500", " bg-lime-500"]);
    setMainPostsIt(varArr);
    changeMainPostsContent(varArr);
  }

  return <div className="mine-main flex flex-col items-center gap-24">
    {/* Section 1 --------------------------------------------------------------------------------------------------------------------------------------*/}
    <div className="mine-pt-131 h-screen w-full flex flex-col items-center">
      <img className="mine-wl-1 absolute top-0 left-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/img11.jpg?alt=media&token=f340934b-fc6e-4ee2-894c-dffc055045bf"/>
      <img className="hidden mine-wl-2 absolute top-0 left-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/img3.jpg?alt=media&token=45436df6-9fb0-4156-aed9-63c1de9d777b"/>
      <img className="hidden mine-wl-3 absolute top-0 left-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/img7.jpg?alt=media&token=b68b7a91-c442-4d3e-a736-53ec063199eb"/>
      <div className="mine-black-gradient absolute top-0 left-0 z-10 w-full h-full"></div>
      <div className="mx-4 z-10 text-white h-full flex flex-col items-center justify-center">
        <div className="mine-wl-1 mine-text-1 font-bold">Мисија</div>
        <div className="hidden mine-wl-2 mine-text-1 font-bold">За Нас</div>
        <div className="hidden mine-wl-3 mine-text-1 font-bold">Визија</div>
        <div className="mine-wl-1 mine-text-2 mine-w-500 text-center">​Градење на личности свесни за своите права и должности, стекнување со знаења и вештини како и стручни квалификации со кои ќе бидат конкурентна работна сила на пазарот на трудот.</div>
        <div className="hidden mine-wl-2 mine-text-2 mine-w-500 text-center">СОУ “Коле Нехтенин” своите корени ги влече уште од пред Втората светска војна, од т.н. занаетчиско училиште што постоело во тоа време во Штип. После војната се трансформирало во училиште...</div>
        <div className="hidden mine-wl-3 mine-text-2 mine-w-500 text-center">Создавање и развивање на безбедна и здрава средина во која учениците  од различни етнички заедници со задоволство ќе доаѓаат и стекнуваат нови знаења и вештини во насока на афирмација на...</div>
        <div className="mine-cp-struki mt-4 flex rounded-full cursor-pointer group bg-lime-500 hover:bg-lime-400">
          <div className="mine-text-1 mine-change-to2 font-bold px-10 py-2">Струки</div>
          <div className="mine-text-1 mine-change-to2 rounded-r-full px-4 py-2 bg-lime-600 group-hover:bg-lime-500">&#10140;</div>
        </div>
      </div>
      <div className="w-full z-10 flex">
        <div className="w-full">
          <div className="w-full flex mine-lower">
          <div id="threeB1" className="mine-loaders mine-text-1 mine-change-to2 text-white font-bold py-4 w-1/3 text-center bg-lime-500 cursor-pointer hover:bg-lime-400">Мисија</div>
          <div id="threeB2" className="mine-loaders mine-text-1 mine-change-to2 text-white font-bold py-4 w-1/3 text-center bg-amber-400 cursor-pointer hover:bg-amber-300">За Нас</div>
          <div id="threeB3" className="mine-loaders mine-text-1 mine-change-to2 text-white font-bold py-4 w-1/3 text-center bg-sky-400 cursor-pointer hover:bg-sky-300">Визија</div>
          </div>
          <div className="w-full flex">
            <div className="w-1/3 h-1">
              <div id="threeL1" className="h-full"></div>
            </div>
            <div className="w-1/3 h-1">
              <div id="threeL2" className="h-full"></div>
            </div>
            <div className="w-1/3 h-1">
              <div id="threeL3" className="h-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End of Section 1*/}
    {/* Section 2 --------------------------------------------------------------------------------------------------------------------------------------*/}
      <div className="mx-4 flex gap-4 items-center bg-lime-500 px-8 py-4 shadow-xl">
        <img className="h-20 mine-change-to-sm" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/student-logo-w.png?alt=media&token=ccab71df-d728-4e0b-b56a-28cb97d63abb"/>
        <div className="mine-br-ucenici mine-text-1 mine-change-to2 text-center text-white font-bold">680 запишани ученици!</div>
      </div>
    {/* End of Section 2 */}
    {/* Section 3 --------------------------------------------------------------------------------------------------------------------------------------*/}
    <div className="flex flex-col items-center gap-4 w-fit mx-4">
      <div className="mine-posts-header flex items-end w-full">
        <div className="mine-text-1 text-lime-500 font-bold pb-2 border-b-4 border-lime-500 w-full">Објави</div>
        <div className="mine-objavi-nav ml-12 flex gap-4">
          <button className="mine-cp-posts mine-text-3 text-white px-4 py-2 bg-lime-500 w-max cursor-pointer font-bold hover:bg-lime-400">Сите Објави</button>
          <button onClick={() => decMainPostsIt()} className={"mine-text-3 text-white px-4 py-2 w-max cursor-pointer mine-mirror hover:bg-lime-400"+unclickable[0]}>&#10140;</button>
          <button onClick={() => incMainPostsIt()} className={"mine-text-3 text-white px-4 py-2 w-max cursor-pointer hover:bg-lime-400"+unclickable[1]}>&#10140;</button>
        </div>
      </div>
      <div className="mine-posts flex gap-4 flex-wrap justify-center">
        <div onClick={() => changePage("post", postsObj[mainPostsIt[0]])} className="mine-to-killer mine-cp-post flex flex-col gap-2 p-4 w-fit shadow-xl cursor-pointer bg-lime-500 hover:bg-lime-400">
          <img className="w-72 h-72 object-cover" src={mainPosts[0].images[0] ? mainPosts[0].images[0] : "https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/objava.png?alt=media&token=90d5efa2-694d-4302-91f8-adb77c7016ec"}></img>
          <div className="mine-text-3 text-white">{mainPosts[0].date[0] + "." + mainPosts[0].date[1] + "." + mainPosts[0].date[2]}</div>
          <div className="mine-text-3 text-white font-bold w-72">{mainPosts[0].title}</div>
        </div>
        <div onClick={() => changePage("post", postsObj[mainPostsIt[1]])} className="mine-to-killer mine-cp-post flex flex-col gap-2 p-4 w-fit shadow-xl cursor-pointer bg-lime-500 hover:bg-lime-400">
          <img className="w-72 h-72 object-cover" src={mainPosts[1].images[0] ? mainPosts[1].images[0] : "https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/objava.png?alt=media&token=90d5efa2-694d-4302-91f8-adb77c7016ec"}></img>
          <div className="mine-text-3 text-white">{mainPosts[1].date[0] + "." + mainPosts[1].date[1] + "." + mainPosts[1].date[2]}</div>
          <div className="mine-text-3 text-white font-bold w-72">{mainPosts[1].title}</div>
        </div> 
        <div onClick={() => changePage("post", postsObj[mainPostsIt[2]])} className="mine-to-killer mine-cp-post flex flex-col gap-2 p-4 w-fit shadow-xl cursor-pointer bg-lime-500 hover:bg-lime-400">
          <img className="w-72 h-72 object-cover" src={mainPosts[2].images[0] ? mainPosts[2].images[0] : "https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/objava.png?alt=media&token=90d5efa2-694d-4302-91f8-adb77c7016ec"}></img>
          <div className="mine-text-3 text-white">{mainPosts[2].date[0] + "." + mainPosts[2].date[1] + "." + mainPosts[2].date[2]}</div>
          <div className="mine-text-3 text-white font-bold w-72">{mainPosts[2].title}</div>
        </div>
      </div>
    </div>
    {/* End of Section 3 */}
    {/* Section 4 --------------------------------------------------------------------------------------------------------------------------------------*/}
    <div className="w-fit flex flex-col gap-4 mx-4">
      <div className="mine-text-1 text-lime-500 font-bold pb-2 border-b-4 border-lime-500 w-full">Нашето Училиште</div>
      <div className="mine-dir-quote flex gap-16 items-center">
        <img className="min-w-72 w-72 mine-bold-shadow" src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/Vraboteni%2Fjovanka_saleva.jpg?alt=media&token=c1f6028b-fbc0-48dc-8113-3f42fa9a0173"/>
        <div className="flex flex-col gap-2">
          <div className="mine-text-2 mine-w-500 text-black/70">​&#10077; Како што електричниот круг бара добри врски за да функционира, така и училиштето успева кога знаењето и трудот течат непрекинато. Секој ученик е искра на иднината, а нашата мисија е да ја насочиме таа енергија кон успех. &#10078;</div>
          <div className="mine-text-2 font-bold text-black/70">-Директор Јованка Шалева</div>
        </div>
      </div>
    </div>
    {/* End of Section 4 */}
  </div>
}

function Posts({changePage}){
  const[postsArr, setPostsArr] = useState([]);
  const[unclickable, setUnclickable] = useState(["bg-lime-400 ","bg-lime-500 "]);
  const[shownPostsArr, setShownPostsArr] = useState([]);
  const[numPostPages, setNumPostPages] = useState([]);
  useEffect(() => {
    let varArr = [];
    let currentPage = 1;
    postsObj.forEach((el) => {
      let date = el["date-posted"].seconds * 1000;
      date = new Date(date);
      const imgArr = el.images;
      varArr.push(
        <div onClick={() => changePage('post', el)} className="mine-post flex flex-col gap-2 p-4 w-fit shadow-xl cursor-pointer bg-lime-500 hover:bg-lime-400">
          <img className="w-72 h-72 object-cover" src={imgArr[0] ? imgArr[0] : "https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/objava.png?alt=media&token=90d5efa2-694d-4302-91f8-adb77c7016ec"}></img>
          <div className="mine-text-3 text-white">{date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()}</div>
          <div className="mine-text-3 text-white font-bold w-72">{el.title}</div>
        </div>
      )
    });
    setPostsArr(varArr);
    setShownPostsArr(varArr.slice(0,6));
    let numP = Math.ceil(varArr.length / 6);
    let varArr1 = [];
    for(let i = 1; i <= numP; i++){
      if(i==1)
        varArr1.push(<button id={"numP"+i} onClick={() => switchPosts(i)} className="mine-text-3 py-2 border-2 px-4 text-lime-500 cursor-pointer hover:text-white bg-lime-400 text-white border-lime-400">{i}</button>);
      else  
        varArr1.push(<button id={"numP"+i} onClick={() => switchPosts(i)} className="mine-text-3 py-2 border-2 border-lime-500 px-4 text-lime-500 cursor-pointer hover:bg-lime-500 hover:text-white">{i}</button>);
    }
    setNumPostPages(varArr1);
    function switchPosts(page){
      document.querySelector("#numP"+currentPage).classList.remove("bg-lime-400");
      document.querySelector("#numP"+currentPage).classList.remove("text-white");
      document.querySelector("#numP"+currentPage).classList.remove("border-lime-400");
      document.querySelector("#numP"+currentPage).classList.add("hover:bg-lime-500");
      document.querySelector("#numP"+currentPage).classList.add("border-lime-500");
      if(page === "-1"){
        currentPage = currentPage - 1 >= 1 ? currentPage-1 : currentPage;
        setShownPostsArr(varArr.slice(currentPage*6 - 6, currentPage*6));
      }
      else if(page === "+1"){
        currentPage = currentPage + 1 <= numP ? currentPage+1 : currentPage;
        setShownPostsArr(varArr.slice(currentPage*6 - 6, currentPage*6));
      }
      else{
        currentPage = page;
        setShownPostsArr(varArr.slice(page*6 - 6, page*6));
      }

      if(currentPage == 1){
        setUnclickable(["bg-lime-400 ","bg-lime-500 "]);
      }
      else if(currentPage == numP){
        setUnclickable(["bg-lime-500 ","bg-lime-400 "]);
      }
      document.querySelector("#numP"+currentPage).classList.add("bg-lime-400");
      document.querySelector("#numP"+currentPage).classList.add("text-white");
      document.querySelector("#numP"+currentPage).classList.remove("border-lime-500");
      document.querySelector("#numP"+currentPage).classList.remove("hover:bg-lime-500");
      document.querySelector("#numP"+currentPage).classList.add("border-lime-400");
      window.scrollTo({top:0, behavior:"smooth"});//Do tuka sum
    };
    document.querySelector(".mine-minus-one").addEventListener("click", () => switchPosts("-1"));
    document.querySelector(".mine-plus-one").addEventListener("click", () => switchPosts("+1"));
  }, []);

  return <div className="mine-mt-131 pt-12 flex flex-col items-center mx-4">
    <div className="w-fit flex flex-col items-center gap-4 mine-post-cont">
      <div className="flex gap-2 w-full">
        <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div className="mine-text-3 text-lime-500 font-bold">Објави</div>
      </div>
      <div className="mine-posts-title mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Објави</div>
      <div className="mine-posts flex gap-4 flex-wrap justify-start">
        {shownPostsArr}
      </div>
      <div className="flex gap-2 self-start flex-wrap">
        <button className={unclickable[0]+"mine-text-3 mine-minus-one py-2 px-4 text-white cursor-pointer hover:bg-lime-400 active:bg-lime-400 mine-mirror"}>&#10140;</button>
        {numPostPages}
        <button className={unclickable[1]+"mine-text-3 mine-plus-one py-2 px-4 text-white cursor-pointer hover:bg-lime-400 active:bg-lime-400"}>&#10140;</button>
      </div>
    </div>
  </div>
}

function Post({changePage, postObj}){
  const imgArr = postObj.images;
  const date = new Date(postObj["date-posted"].seconds * 1000);
  const dateArr = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  let images1 = [];
  let images2 = [];
  let odd = imgArr.length % 2;
  if(imgArr[0] != "")
  imgArr.forEach((i, it) => {
    if(it < (imgArr.length + odd) / 2)
    images1.push(
      <img onClick={() => {window.open(i, "_blank")}} className="w-full object-contain shadow-xl rounded-xl cursor-pointer hover:scale-105" src={i}></img>
    )
    else
    images2.push(
      <img onClick={() => {window.open(i, "_blank")}} className="w-full  object-contain shadow-xl rounded-xl cursor-pointer hover:scale-105" src={i}></img>
    )
  })
  const textArr = postObj.text.split("<br/>");
  let textArr1 = [];
  textArr.forEach(e => {
    textArr1.push(e);
    textArr1.push("<br/>");
  });
  textArr1.pop();
  let finalTextArr = textArr1.map(e => {
    if(e == "<br/>")
      return <br/>;
      return e;
  })
  return <div className="flex flex-col items-center mine-mt-131 pt-12 mx-4">
    <div className="flex flex-col gap-4 mine-w-700">
      <div className="flex gap-2 w-full">
        <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div onClick={() => changePage('posts')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Објави</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div className="mine-text-3 text-lime-500 font-bold">Објава</div>
      </div>
      <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">{postObj.title}</div>
      <div className="mine-text-3 text-lime-500">Posted &#8226; {dateArr[0] + "." + dateArr[1] + "." + dateArr[2]}</div>
      <div className="mine-text-3 text-black/70">{finalTextArr}</div>
      <div className="w-full flex items-start justify-between">
        <div className="mine-w-49 flex flex-col gap-4">
          {images1}
        </div>
        <div className="mine-w-49 flex flex-col gap-4">
          {images2}
        </div>
      </div>
    </div>
  </div>
}

function ZaNas({changePage}){
  return <div className="mine-mt-131 flex flex-col items-center mx-4 pt-12">
  <div className="mine-w-700 flex flex-col items-center gap-4">
    <div className="flex gap-2 self-start">
      <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
      <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
      <div className="mine-text-3 text-lime-500 font-bold">За Нас / Контакт</div>
    </div>
    <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">За Нас</div>
    <img src='https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/img1.jpg?alt=media&token=e551a161-bc7e-4520-8ac3-cd07a7c27071' className="h-96 w-full object-cover"/>
    <div className="mine-text-3 text-black/70">
      СОУ “Коле Нехтенин” своите корени ги влече уште од пред Втората светска војна, од т.н. занаетчиско училиште што постоело во тоа време во Штип. После војната се трансформирало во училиште за ученици за стопанство – УЗУС.<br/><br/>
      1965/66 се основа како Металско училиште со практична обука со паралелки за ученици во стопанството-решение на СО Штип од 06,02,1968.<br/><br/>
      1973 прераснува во Металски училишен центар-решение на Окружен стопански суд во Штип од 05.03.1973<br/><br/>
      1975/76 отпочнува со настава Електро отсек-Одлука на СО Штип од 20.08.1974<br/><br/>
      СОУ “Коле Нехтенин” – Штип, денес има околу 800 ученици од електро-техничка, машинска и угостителско-туристичка струка, кои организирани во 28 паралелки од четиригодишно и тригодишно образование го изучуваат своето занимање, стекнуваат знаења, вештини и умеења во широка и пространа училишна зграда составена од 14 училници, 9 кабинети, спортска сала, машинска работилница и административни простории. Наставниот и останатиот персонал со своето знаење, искуство и пристапност во голема мерка даваат придонес за одвивање на квалитетна и успешна настава.
    </div>
    <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full mt-8">Контакт</div>
    <div className="w-full flex flex-col gap-2">
      <div className="mine-text-2 text-lime-500 font-bold pb-2 border-b-4 border-lime-500 w-full">Тел.бр</div>
      <div className="mine-text-2 text-black/70">+389 32 391 384</div>
      <div className="mine-text-2 text-black/70">+389 32 ​607 384</div>
    </div>
    <div className="w-full flex flex-col gap-2">
      <div className="mine-text-2 text-lime-500 font-bold pb-2 border-b-4 border-lime-500 w-full">E-Mail</div>
      <div className="mine-text-2 text-black/70">kontakt.kolenehtenin@gmail.com</div>
    </div>
    <div className="w-full flex flex-col gap-2">
      <div className="mine-text-2 text-lime-500 font-bold pb-2 border-b-4 border-lime-500 w-full">Адреса</div>
      <div className="mine-text-2 text-black/70">Булевар Гоце Делчев бр. 101</div>
      <div className="mine-text-2 text-black/70">Штип, 2000</div>
    </div>
  </div>
</div>
}

function Galerija(){
  return <div className="mine-mt-131 mx-4 flex flex-col">
    <div className="flex flex-col items-center justify-center pt-24">
      <div className="mine-text-1 text-lime-500 font-bold text-center">Оваа страна е моментално во обработка!</div>
    </div>
  </div>
}

function Vraboteni({changePage}){
  const[elektroStat, setElektroStat] = useState("hidden ");
  const[elektroSign, setElektroSign] = useState("");
  function toggleEletroAktiv(){
    if(elektroStat == "hidden "){
      setElektroStat("flex ");
      setElektroSign("rotate-45 ");
    }
    else{
      setElektroStat("hidden ");
      setElektroSign("");
    }
  }
  const[masinskiStat, setMasinskiStat] = useState("hidden ");
  const[masinskiSign, setMasinskiSign] = useState("");
  function toggleMasinskiAktiv(){
    if(masinskiStat == "hidden "){
      setMasinskiStat("flex ");
      setMasinskiSign("rotate-45 ");
    }
    else{
      setMasinskiStat("hidden ");
      setMasinskiSign("");
    }
  }
  const[ugostitelskiStat, setUgostitelskiStat] = useState("hidden ");
  const[ugostitelskiSign, setUgostitelskiSign] = useState("");
  function toggleUgostitelskiAktiv(){
    if(ugostitelskiStat == "hidden "){
      setUgostitelskiStat("flex ");
      setUgostitelskiSign("rotate-45 ");
    }
    else{
      setUgostitelskiStat("hidden ");
      setUgostitelskiSign("");
    }
  }
  const[matematickiStat, setMatematickiStat] = useState("hidden ");
  const[matematickiSign, setMatematickiSign] = useState("");
  function toggleMatematickiAktiv(){
    if(matematickiStat == "hidden "){
      setMatematickiStat("flex ");
      setMatematickiSign("rotate-45 ");
    }
    else{
      setMatematickiStat("hidden ");
      setMatematickiSign("");
    }
  }
  const[opstStat, setOpstStat] = useState("hidden ");
  const[opstSign, setOpstSign] = useState("");
  function toggleOpstAktiv(){
    if(opstStat == "hidden "){
      setOpstStat("flex ");
      setOpstSign("rotate-45 ");
    }
    else{
      setOpstStat("hidden ");
      setOpstSign("");
    }
  }
  let pedagozi = [];
  let elektroProfesori = [];
  let masinskaProfesori = [];
  let ugostitelskoProfesori = [];
  let matematickaProfesori = [];
  let opstoProfesori = [];

  vraboteniObj.forEach(e => {
    let el = <div className="mine-prof-div flex gap-8">
      <div className="mine-text-3 mine-w-500 text-end text-black/70">
        <b>{e.ime}</b><br/><br/>
        Email: <b>{e.email}</b><br/><br/>
        {e.struka}
      </div>
      {/* <img src={require('./media/vraboteni/' + e.image)} className="w-48 h-64 object-cover"/> */}
      <img src={e.image} className="w-48 h-64 object-cover rounded-xl"/>
    </div>;
    if(e.aktiv=="pedagozi"){
      pedagozi.push(el);
      pedagozi.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
    else if(e.aktiv=="elektro"){
      elektroProfesori.push(el);
      elektroProfesori.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
    else if(e.aktiv=="masinski"){
      masinskaProfesori.push(el);
      masinskaProfesori.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
    else if(e.aktiv=="ugostitelski"){
      ugostitelskoProfesori.push(el);
      ugostitelskoProfesori.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
    else if(e.aktiv=="matematicki"){
      matematickaProfesori.push(el);
      matematickaProfesori.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
    else if(e.aktiv=="opst"){
      opstoProfesori.push(el);
      opstoProfesori.push(<div className="w-full bg-lime-500 h-1 rounded-full"></div>);
    }
  });
  pedagozi.pop();elektroProfesori.pop();masinskaProfesori.pop();ugostitelskoProfesori.pop();matematickaProfesori.pop();opstoProfesori.pop();
  //<div className="w-full bg-lime-500 h-1 rounded-full"></div>
  return <div className="mine-mt-131 flex justify-center mx-4 pt-12">
    <div className="mine-vraboteni w-fit flex flex-col items-end gap-4">
      <div className="flex gap-2 self-start">
        <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div className="mine-text-3 text-lime-500 font-bold">Вработени</div>
      </div>
      <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Директор</div>
      <div className="mine-prof-div flex gap-8">
        <div className="mine-text-3 mine-w-500 text-end text-black/70">
        <b>Јованка Шалева</b>, родена 1977 година е дипломиран професор по македонски јазик и литература на Филолошкиот факултет “Блаже Конески” при Универзитет “Св. Кирил и Методиј” во Скопје.<br/><br/>
        Од 2015 година работи како професор по македонски јазик и литература во СОУ “Коле Нехтенин”-Штип.<br/><br/>
        На 14.2.2022 г. Јованка Шалева е именувана за директор на СОУ “Коле Нехтенин”-Штип
        </div>
        <img src="https://firebasestorage.googleapis.com/v0/b/kole-nehtenin.appspot.com/o/Vraboteni%2Fjovanka_saleva.jpg?alt=media&token=c1f6028b-fbc0-48dc-8113-3f42fa9a0173" className="w-64 h-96 object-cover rounded-xl"/>
      </div>
      <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Педагози</div>
      {pedagozi}
      <div onClick={() => toggleEletroAktiv()} className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between hover:bg-lime-400"><span>Електро Актив / Професори</span><span className={elektroSign + "mine-text-1"}>+</span></div>
      <div className={elektroStat + "mine-vraboteni mine-prof-aktiv flex-col items-end gap-4 w-full"}>
        {elektroProfesori}
      </div>
      <div onClick={() => toggleMasinskiAktiv()} className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between hover:bg-lime-400"><span>Машински Актив / Професори</span><span className={masinskiSign + "mine-text-1"}>+</span></div>
      <div className={masinskiStat + "mine-vraboteni mine-prof-aktiv flex-col items-end gap-4 w-full"}>
        {masinskaProfesori}
      </div>
      <div onClick={() => toggleUgostitelskiAktiv()} className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between hover:bg-lime-400"><span>Угостителскo-туристички Актив / Професори</span><span className={ugostitelskiSign + "mine-text-1"}>+</span></div>
      <div className={ugostitelskiStat + "mine-vraboteni mine-prof-aktiv flex-col items-end gap-4 w-full"}>
        {ugostitelskoProfesori}
      </div>
      <div onClick={() => toggleMatematickiAktiv()} className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between hover:bg-lime-400"><span>Природно-математички Актив / Професори</span><span className={matematickiSign + "mine-text-1"}>+</span></div>
      <div className={matematickiStat + "mine-vraboteni mine-prof-aktiv flex-col items-end gap-4 w-full"}>
        {matematickaProfesori}
      </div>
      <div onClick={() => toggleOpstAktiv()} className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between hover:bg-lime-400"><span>Општообразовен Актив / Професори</span><span className={opstSign + "mine-text-1"}>+</span></div>
      <div className={opstStat + "mine-vraboteni mine-prof-aktiv flex-col items-end gap-4 w-full"}>
        {opstoProfesori}
      </div>
    </div>
  </div>
}

function Links({changePage}){
  let dopolnitelnoLinks = links.map(el => {
    if(el.kategorija == "dopolnitelno"){
      return <div onClick={() => window.location.href=el.link} className="mine-text-2 text-lime-500 hover:text-lime-400 cursor-pointer pb-2 border-b-2 border-lime-500">{el.naziv}</div>
    }
  });
  let nagradiLinks = links.map(el => {
    if(el.kategorija == "nagradi"){
      return <div onClick={() => window.location.href=el.link} className="mine-text-2 text-lime-500 hover:text-lime-400 cursor-pointer pb-2 border-b-2 border-lime-500">{el.naziv}</div>
    }
  });
  let maturskiIspitiLinks = links.map(el => {
    if(el.kategorija == "maturskiIspiti"){
      return <div onClick={() => window.location.href=el.link} className="mine-text-2 text-lime-500 hover:text-lime-400 cursor-pointer pb-2 border-b-2 border-lime-500">{el.naziv}</div>
    }
  });
  let zavrsniSmetkiLinks = links.map(el => {
    if(el.kategorija == "zavrsniSmetki"){
      return <div onClick={() => window.location.href=el.link} className="mine-text-2 text-lime-500 hover:text-lime-400 cursor-pointer pb-2 border-b-2 border-lime-500">{el.naziv}</div>
    }
  });
  let korisniLinks = links.map(el => {
    if(el.kategorija == "korisni"){
      return <div onClick={() => window.location.href=el.link} className="mine-text-2 text-lime-500 hover:text-lime-400 cursor-pointer pb-2 border-b-2 border-lime-500">{el.naziv}</div>
    }
  });
  return <div className="flex flex-col items-center mine-mt-131 pt-12 mx-4">
    <div className="flex flex-col gap-4 mine-w-600">
      <div className="flex gap-2 self-start">
        <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div className="mine-text-3 text-lime-500 font-bold">Линкови</div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Корисни линкови</div>
        {korisniLinks}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Матурски испити и прашања</div>
        {maturskiIspitiLinks}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Награди и признанија</div>
        {nagradiLinks}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Дополнително</div>
        {dopolnitelnoLinks}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="mine-text-2 text-white font-bold py-2 px-4 bg-lime-500 w-full">Завршни сметки</div>
        {zavrsniSmetkiLinks}
      </div>
    </div>
  </div>
}

function Struki({changePage}){
  const[elektroStat, setElektroStat] = useState("hidden ");
  const[masinskiStat, setMasinskiStat] = useState("hidden ");
  const[ugostitelskiStat, setUgostitelskiStat] = useState("hidden ");

  const[elektroSign, setElektroSign] = useState("");
  const[masinskiSign, setMasinskiSign] = useState("");
  const[ugostitelskiSign, setUgostitelskiSign] = useState("");

  const[strukiStat, setStrukiStat] = useState([
    "hidden ","hidden ","hidden ","hidden ","hidden ","hidden ","hidden ","hidden ","hidden ","hidden "
  ]);

  const[strukiSigns, setStrukiSigns] = useState([
    "","","","","","","","","","",
  ]);

  function toggleElektro(){
    if(elektroStat == "flex "){
      setElektroStat("hidden ");
      setElektroSign("");
    }
    else{
      setElektroStat("flex ");
      setElektroSign("rotate-45 ");
    }
  }
  function toggleMasinski(){
    if(masinskiStat == "flex "){
      setMasinskiStat("hidden ");
      setMasinskiSign("");
    }
    else{
      setMasinskiStat("flex ");
      setMasinskiSign("rotate-45 ");
    }
  }
  function toggleUgostitelsko(){
    if(ugostitelskiStat == "flex "){
      setUgostitelskiStat("hidden ");
      setUgostitelskiSign("");
    }
    else{
      setUgostitelskiStat("flex ");
      setUgostitelskiSign("rotate-45 ");
    }
  }

  function toggleStruka(arg){
    let strukiStatCp = strukiStat.slice(0,);
    if(strukiStat[arg] == "hidden "){
      strukiStatCp.splice(arg, 1, "flex ")
      setStrukiStat(strukiStatCp);
    }
    else{
      strukiStatCp.splice(arg, 1, "hidden ")
      setStrukiStat(strukiStatCp);
    }
  }
  function toggleStrukaSign(arg){
    let strukiSignsCp = strukiSigns.slice(0,);
    if(strukiSigns[arg] == ""){
      strukiSignsCp.splice(arg, 1, "rotate-45 ")
      setStrukiSigns(strukiSignsCp);
    }
    else{
      strukiSignsCp.splice(arg, 1, "")
      setStrukiSigns(strukiSignsCp);
    }
  }

  return <div className="mine-mt-131 flex flex-col items-center pt-12 mx-4">
    <div className="flex flex-col gap-4 mine-w-600">
      <div className="flex gap-2 self-start">
        <div onClick={() => changePage('main')} className="mine-text-3 text-lime-500/70 hover:text-lime-500 font-bold cursor-pointer">Почетна</div>
        <div className="mine-text-3 text-lime-500/70 font-bold">&#10095;</div>
        <div className="mine-text-3 text-lime-500 font-bold">Струки</div>
      </div>
      {/* Struki 1 */}
      <button onClick={() => toggleElektro()} className="mine-text-2 text-start text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-400"><span>Електротехнички Струки</span><span className={elektroSign + "mine-text-1"}>+</span></button>
      <div className={elektroStat + "flex-col gap-4"}>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(0);toggleStruka(0)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Електротехничар за компјутерска техника и автоматика</span><span className={strukiSigns[0] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[0] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
            Образовниот профил електротехничар за компјутерска техника и автоматика е оспособен:<br/>
            – да ги применува законите на електротехниката во управувањето со компјутерски системи;<br/>
            – да ги подготвува компјутерите за корисниците;<br/>
            – да го одржува алатот, приборот, инструментите и уредите;<br/>
            – да ја контролира исправноста на компјутерската техника;<br/>
            – да истражува, контролира и поправа грешки на ниво на копјутерската техника;<br/>
            – да избере мерни иструменти за контрола и пребарување на грешки;<br/>
            – да користи и да изготвува техничка документација;<br/>
            – да ја монтира, инсталира и користи опрема;<br/>
            – да прилагоди и модифицира опрема со помош на прирачници, дијаграми итн.;<br/>
            – да ги процени можните и најисплаливите економски поправки во зависност од желбата на корисникот;<br/>
            – да ги планира и организира работните задачи;<br/><br/>


            Времетраење на образовниот профил: 4 години<br/>
            Број на ученици: 68<br/>
            Број на паралелки: 2<br/>
            Минимум потребни поени: 55<br/>
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(1);toggleStruka(1)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Електротехничар – енергетичар</span><span className={strukiSigns[1] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[1] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Електротехничарот – енергетичар е способен и умее:<br/>
          – да ги применува електротехничките законитости;<br/>
          – да го познава принципот на работа и карактеристиките на електричните машини, апарати и инсталации;<br/>
          – да ја испитува исправноста на електричните машини, апарати и инсталации;<br/>
          – да ги лоцира дефектите на електричните машини, апарати и инсталации;<br/>
          – да ја контролира исправноста на електричните машини, апарати и инсталации;<br/>
          – да се служи со електричните мерни инструменти и уреди;<br/>
          – да монтира и демонтира кабли и да изведува соодветни споеви;<br/>
          – да монтира и демонтира електрични машини, апарати и уреди;<br/>
          – да заменува делови во електрични машини, апарати и уреди;<br/>
          – да изработува намотки за електрични машини, апарати и уреди;<br/>
          – да организира и применува хигиено – техничка заштита;<br/>
          – да води мал бизнис;<br/><br/>

          Времетраење на образовниот профил: 4 години
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(2);toggleStruka(2)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Електротехничар за електроника и телекомуникации</span><span className={strukiSigns[2] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[2] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Образовниот профил  електротехничар за електроника и телекомуникации е оспособен:<br/>
          – да ги применува законите на електротехниката;<br/>
          – да го користи компјутерот за електрични шеми;<br/>
          – да открива и отстранува дефекти;<br/>
          – да се служи и изготвува техничка документација;<br/>
          – да тестира и контролира електронски уреди;<br/>
          – да ја одржува и заштитува опремата;<br/>
          – да врши мерења и користи инструменти;<br/>
          – да користи наменски програмски пакети;<br/><br/>
          Времетраење на образовниот профил: 4 години<br/>
          Број на ученици: 34<br/>
          Број на паралелки: 1<br/>
          Минимум потребни поени: 50
          </div>
        </div>
        
        {/* <table className="block border-t-2 border-lime-500 text-black/70">
          <thead>
            <tr className="border-b-2 border-lime-500">
              <th className="border-x-2 border-lime-500 p-1">Струка</th>
              <th className="border-x-2 border-lime-500 p-1">Број на ученици</th>
              <th className="border-x-2 border-lime-500 p-1">Паралелки</th>
              <th className="border-x-2 border-lime-500 p-1">Траење (години)</th>
              <th className="border-x-2 border-lime-500 p-1">Мин. Поени</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-lime-500">
              <td className="border-x-2 border-lime-500 text-center p-1">Електротехничар - енергетичар</td>
              <td className="border-x-2 border-lime-500 text-center p-1">34</td>
              <td className="border-x-2 border-lime-500 text-center p-1">1</td>
              <td className="border-x-2 border-lime-500 text-center p-1">4</td>
              <td className="border-x-2 border-lime-500 text-center p-1">45</td>
            </tr>
            <tr className="border-b-2 border-lime-500">
              <td className="border-x-2 border-lime-500 text-center p-1">Електротехничар за електроника и телекомуникации</td>
              <td className="border-x-2 border-lime-500 text-center p-1">34</td>
              <td className="border-x-2 border-lime-500 text-center p-1">1</td>
              <td className="border-x-2 border-lime-500 text-center p-1">4</td>
              <td className="border-x-2 border-lime-500 text-center p-1">50</td>
            </tr>
            <tr className="border-b-2 border-lime-500">
              <td className="border-x-2 border-lime-500 text-center p-1">Електротехничар за компјутерска техника и автоматика</td>
              <td className="border-x-2 border-lime-500 text-center p-1">68</td>
              <td className="border-x-2 border-lime-500 text-center p-1">2</td>
              <td className="border-x-2 border-lime-500 text-center p-1">4</td>
              <td className="border-x-2 border-lime-500 text-center p-1">55</td>
            </tr>
            <tr className="border-b-2 border-lime-500">
              <td className="border-x-2 border-lime-500 text-center p-1">Електромеханичар за компјутерска техника и технологија / електромеханичар</td>
              <td className="border-x-2 border-lime-500 text-center p-1">34</td>
              <td className="border-x-2 border-lime-500 text-center p-1">1</td>
              <td className="border-x-2 border-lime-500 text-center p-1">3</td>
              <td className="border-x-2 border-lime-500 text-center p-1">30</td>
            </tr>
          </tbody>
        </table> */}
      </div>
      {/* Struki 2 */}
      <button onClick={() => toggleMasinski()} className="mine-text-2 text-start text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-400"><span>Машински Струки</span><span className={masinskiSign+"mine-text-1"}>+</span></button>
      <div className={masinskiStat + "flex-col gap-4"}>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(3);toggleStruka(3)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Техничар за компјутерско управување<br/>(4 год.)</span><span className={strukiSigns[3] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[3] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Образовниот профил техничар за компјутерско управување може:<br/>
          – да моделира и конструира машински елементи и склопови со компјутер;<br/>
          – да конфигурира електропневматски и хидраулични системи;<br/>
          – да програмира програмабилни логички контролери;<br/>
          – да програмира компјутерски управувани машини;<br/>
          – примена на CAD – CAM технологии;<br/>
          – да проектира производствени технолошки процеси и системи;<br/>
          – да го контролира квалитетот на готовите производи;<br/>
          – да идентификува неисправности на компјутерски управувани машини и системи настанати во тек на експлоатацијата;<br/>
          – да се грижи за хигиенско – техничката заштита;<br/>
          – да се грижи за заштита на животната средина;<br/><br/>

          Времетраење на образовниот профил: 4 години
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(4);toggleStruka(4)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Машински техничар<br/>(4 год.)</span><span className={strukiSigns[4] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[4] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Машинскиот техничар е оспособен:<br/>
          – да ги применува знаконитостите од механиката, хидрауликата и топлотехниката;<br/>
          – да организира работни задачи од областа на механизацијата и автоматизацијата во производните и непроизводните процеси;<br/>
          – да применува теортски знаења за машинските елементи во машинското производство;<br/>
          – да го користи компјутерот за изготвување на технички дизајн;<br/>
          – да ја користи компјутерстката библиотека за Autocad;<br/>
          – да го одржува алатот, приборот и машините;<br/>
          – да ја контролира исправноста на машините, алатите и точноста на габаритите на машинските елементи;<br/>
          – да монтира машнски елементи;<br/><br/>

          Времетраење на образовниот профил: 4 години<br/>
          Број на ученици: 34<br/>
          Број на паралелки: 1<br/>
          Минимум потребни поени: 45
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(5);toggleStruka(5)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Машински енергетски техничар<br/>(4 год.)</span><span className={strukiSigns[5] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[5] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Образовниот профил машинско-енергетски техничар е способен:<br/>
          – Да ги применува законитостите од механиката, хидрауликата и топлотехниката;<br/>
          – Да организира работни задачи од областа хидротехниката и пневматиката;<br/>
          – Да ги применува теоретските знаења за машинските елементи во дизајнирање на цилиндри, клипови, цевни елементи, затнувачи;<br/>
          – Да ја користи компјутерската библиотека за CAD и да го користи компјутерот за изготвување на технички дизајн;<br/>
          – Да го одржува алатот, приборот и машините а притоа да ја контролира исправноста на термоуредите, хидрауличните уреди, компресорските и ладилните уреди и др.;<br/>
          – Да монтира машински елементи;<br/>
          – Да открива грешки во изведените мали системи за климатизација;<br/>
          – Да монтира и демонтира инструменти за мерење на хидраулични, компресорски и термопостројки.<br/><br/>

          Времетраење на образовниот профил: 4 години (III ниво)<br/>
          Број на ученици: 34<br/>
          Број на паралелки: 1<br/>
          Минимум потребни поени: 45
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(6);toggleStruka(6)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Автотехничар - мехатроничар<br/>(4 год.)</span><span className={strukiSigns[6] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[6] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Со изучување на образовниот профил автотехничар – мехатроничар, ученикот се здобива со знаења од областа на математиката, физиката, техничкото цртање, машинските елемнти, технологијата на моторните возила, контролната технологија, како и електротехниката и електрониката.
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(7);toggleStruka(7)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Машински механичар<br/>(3 год.)</span><span className={strukiSigns[7] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[7] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          По завршувањето на училиштето ученикот е способен за следниве работни задачи:<br/><br/>

          -одржување и поправка на машини и системи;<br/>
          -монтирање и демонтирање делови и групи од елементи;<br/>
          -лоцирање и отстранување неисправности;<br/>
          -пролагодување, преправање и проширување погонски системи;<br/>
          -изработување резервни делови и конструкциски елементи.
          </div>
        </div>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(8);toggleStruka(8)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Конструкциски механичар<br/>(3 год.)</span><span className={strukiSigns[8] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[8] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Образовниот профил конструкциски механичар може да:<br/><br/>

          -подготвува елементи: лимови, профили и цефки за спојување на конструкции и цевководи;<br/>
          -спојува разни видови конструкции и цевководи со помош на завртки и навртки;<br/>
          -спојува со електролачно и гасно заварување елементи во конструкции и цевководи;<br/>
          -спојува елементи во конструкции со МИГ/МАГ постапка на заварување;<br/>
          -одржува конструкции и транспортни ленти, кровни конструкции, контејнери и резервоари;<br/>
          -изработува резервни делови и конструкциски елементи;<br/>
          -учествува во транспорт на готови конструкции;<br/>
          -применува хигенско – техничка заштита;<br/>
          -се грижи за заштитата на работната и животната средина;<br/><br/>

          Времетраење на образовниот профил: 3 години<br/>
          Број на ученици: 24<br/>
          Број на паралелки: 1<br/>
          Минимум потребни поени: 30
          </div>
        </div>
      </div>
      {/* Struki 3 */}
      <button onClick={() => toggleUgostitelsko()} className="mine-text-2 text-start text-white font-bold py-2 px-4 bg-lime-500 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-400"><span>Угостителски Струки</span><span className={ugostitelskiSign+"mine-text-1"}>+</span></button>
      <div className={ugostitelskiStat + "flex-col gap-4"}>
        <div className="flex flex-col w-full border-2 border-lime-500">
          <button onClick={() => {toggleStrukaSign(9);toggleStruka(9)}} className="mine-text-2 text-start text-lime-500 font-bold py-2 px-4 w-full cursor-pointer flex items-center justify-between gap-4 hover:bg-lime-500 hover:text-white"><span>Келнер - готвач<br/>(3 год.)</span><span className={strukiSigns[9] + "mine-text-1"}>+</span></button>
          <div className={strukiStat[9] + "mine-text-3 p-4 flex-col gap-4 text-black/70"}>
          Учениците кои ќе се запишат на образовниот профил келнер – готвач ќе се здобијат со знаење од областа на географијата, информатиката, математиката и од областа на останатите општообразовни предмети. Учениците се здобиваат и со стручно знаење од областа на хигиената, готварството, храната и пијалоците, психологијата, декорацијата во готварството, посложувањето. Учениците имаат и практична настава.
          </div>
        </div>
      </div>
    </div>
  </div>
}

function Loading(){
  return <div className="h-screen w-full flex items-center justify-center mine-text-1 font-bold text-lime-500">Loading...</div>
}

function App() {
  const[showScrollUp, setShowScrollUp] = useState("hidden ");
  const[currentPage, setCurrentPage] = useState(<Loading/>);
  useEffect(() => {
    document.title = "SOU Kole Nehtenin";
    async function fetchCollection() {
      const q = query(collection(db, "posts"), orderBy("date-posted", "desc"));
      const querySnapshot = await getDocs(q);
      if(!postsObj.length)
      querySnapshot.forEach((doc) => {
        postsObj.push(doc.data());
      });
      const q1 = query(collection(db, "vraboteni"), orderBy("ime", "asc"));
      const querySnapshot1 = await getDocs(q1);
      if(!vraboteniObj.length)
      querySnapshot1.forEach((doc) => {
        vraboteniObj.push(doc.data());
      });
      const q2 = query(collection(db, "links"), orderBy("naziv", "asc"));
      const querySnapshot2 = await getDocs(q2);
      if(!links.length){
        querySnapshot2.forEach(doc => {
          links.push(doc.data());
        })
      }
      changePage("main");
    }
    fetchCollection();
    window.addEventListener("scroll", () => {
      if(window.scrollY <= 10){
        setShowScrollUp("hidden ");
      }
      else{
        setShowScrollUp("flex ");
      }
    })
  },[])

  function changePage(page, postObj){
    let pageVar;
    switch(page){
      case 'main': pageVar = <Main changePage={changePage}/>;
      break;
      case 'posts': pageVar = <Posts changePage={changePage}/>;
      break;
      case 'links': pageVar = <Links changePage={changePage}/>;
      break;
      case 'vraboteni': pageVar = <Vraboteni changePage={changePage}/>;
      break;
      case 'struki': pageVar = <Struki changePage={changePage}/>;
      break;
      case 'post': pageVar = <Post changePage={changePage} postObj={postObj}/>;
      break;
      case 'zanas': pageVar = <ZaNas changePage={changePage}/>;
      break;
      case 'galerija': pageVar = <Galerija changePage={changePage}/>;
      break;
    }
    setCurrentPage(pageVar);
    document.querySelector("html").scrollIntoView({'block':"start"});
  }
  return <>
    <Header changePage={changePage}/>
    {currentPage}
    <Footer changePage={changePage}/>
    {/* <button onClick={()=>{window.scrollTo({top:0, behavior:"smooth",})}} className={showScrollUp + "mine-show-up fixed shadow-lg z-10 right-3 bottom-3 bg-lime-500 active:bg-lime-400 hover:bg-lime-400 rounded-full text-white text-2xl w-12 h-12 justify-center items-center font-bold"}>&#129121;</button> */}
    <button onClick={()=>{window.scrollTo({top:0, behavior:"smooth",})}} className={showScrollUp + "mine-show-up fixed shadow-lg z-10 right-3 bottom-3 bg-lime-500 active:bg-lime-400 hover:bg-lime-400 rounded-full text-white text-2xl w-12 h-12 justify-center items-center mine-rotate-270"}>&#10140;</button>
  </>
}

export default App;